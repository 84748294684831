<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/bedfloor/UpdateFloor"
      finish-router="bedfloor"
      permission="Bed_Floor_Edit"
    >
      <template #facility="scope">
        <slot-facility :company-id="scope.rowData.companyID" :facility-id="scope.rowData.facilityID" :row-data="scope.rowData" :is-edit="scope.isEdit"/>
      </template>

      <template #room="scope">
        <SlotRoom :row-data="scope.rowData"  :is-edit="scope.isEdit" :slotConfig="scope.slotConfig"/>
      </template>
    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/floor/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";
import PageContent from "@/components/PageContent";
import SlotRoom from "@/views/floor/SlotRoom";
import SlotFacility from "@/views/floor/SlotFacility";

export default {
  name: "Edit",
  components: {
    SlotFacility,
    SlotRoom,
    PageContent,
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('bedfloor') + ' - ' + common.getI18n('common.edit'),
      id: this.$router.currentRoute.params.id,
      fields,
      row_data: {}
    }
  },
  created() {
    reloadFieldData()
  },
  mounted() {
    this.id = common.decrypt(this.id)
    if (this.id === undefined) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
    common.apiGetData('/bedfloor/GetFloor?floorID='+this.id).then(res => {
      this.row_data = res.data.bedFloor
    })
  }
}
</script>

<style scoped>

</style>
